import { requireFun } from '@/utils'
import {
  charLenLimitConstructor,
  is4And20char,
  isW,
  isRobust,
  isEmail,
  validateSpecialCharacter
} from '@/utils/validate'

const is6And30char = charLenLimitConstructor(2, 30)

export const addUserField = [
  {
    label: '用户昵称',
    prop: 'nickName',
    name: 'nickName',
    colNum: 24,
    placeholder: '请输入用户昵称',
    rule: [
      {
        required: true,
        message: requireFun('用户昵称')
      },
      {
        validator: is6And30char
      },
      {
        validator: validateSpecialCharacter
      }
    ]
  },
  {
    label: '登录账号',
    prop: 'accountName',
    name: 'accountName',
    colNum: 24,
    placeholder: '请输入登录账号',
    rule: [
      {
        required: true,
        message: requireFun('登录账号')
      },
      {
        validator: isW
      },
      {
        validator: is4And20char
      }
    ]
  },
  {
    label: '用户邮箱',
    prop: 'email',
    name: 'email',
    colNum: 24,
    placeholder: '请输入用户邮箱',
    rule: [
      {
        required: true,
        message: requireFun('用户邮箱')
      },
      {
        validator: charLenLimitConstructor(8, 50)
      },
      {
        validator: isEmail
      }
    ]
  },
  {
    label: '设置密码',
    prop: 'password',
    name: 'password',
    type: 'password',
    placeholder: '请输入密码',
    colNum: 24,
    // slotName: 'passwordSlot',
    rule: [
      {
        required: true,
        message: requireFun('密码')
      },
      {
        validator: isRobust
      }
    ]
  },
  {
    label: '确认密码',
    prop: 'confirmPassword',
    name: 'confirmPassword',
    type: 'password',
    colNum: 24,
    // slotName: 'password2Slot',
    placeholder: '请再次输入密码',
    rule: [
      {
        required: true,
        message: requireFun('确认密码')
      }
      // {
      //   validator: isRobust,
      // },
    ]
  },
  {
    label: '选择角色',
    prop: 'roleIdList',
    name: 'roleIdList',
    colNum: 24,
    slotName: 'rolesSlot'
  }
]

export const editUserField = [
  {
    label: '用户昵称',
    prop: 'nickName',
    name: 'nickName',
    colNum: 24,
    placeholder: '请输入用户昵称',
    rule: [
      {
        required: true,
        message: requireFun('用户昵称')
      },
      {
        validator: is6And30char
      },
      {
        validator: validateSpecialCharacter
      }
    ]
  },
  {
    label: '登录账号',
    prop: 'accountName',
    name: 'accountName',
    colNum: 24,
    disabled: true,
    placeholder: '请输入登录账号',
    rule: [
      {
        required: true,
        message: requireFun('登录账号')
      },
      {
        validator: isW
      },
      {
        validator: is4And20char
      }
    ]
  },
  {
    label: '用户邮箱',
    prop: 'email',
    name: 'email',
    colNum: 24,
    placeholder: '请输入用户邮箱',
    rule: [
      {
        required: true,
        message: requireFun('用户邮箱')
      },
      {
        validator: charLenLimitConstructor(8, 50)
      },
      {
        validator: isEmail
      }
    ]
  },
  {
    label: '选择角色',
    prop: 'roleIdList',
    name: 'roleIdList',
    colNum: 24,
    slotName: 'rolesSlot'
  }
]
