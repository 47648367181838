import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

/**
 * 创建或更新角色
 * createOrUpdateAdmin-后台端 createOrUpdateBusiness-业务端 createOrUpdateFactory-生产
 * @param {object} data
 * @param menuType
 * @returns
 */
export function createOrUpdateAdmin(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/authService/role/createOrUpdateAdmin',
      method: 'post',
      data
    }),
    'adminRoleList'
  )
}
export function createOrUpdateBusiness(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/authService/role/createOrUpdateBusiness',
      method: 'post',
      data
    }),
    'businessRoleList'
  )
}
export function createOrUpdateFactory(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/authService/role/createOrUpdateFactory',
      method: 'post',
      data
    }),
    'factoryRoleList'
  )
}


// 获取所有的Role
export function getRoles() {
  return request({
    url: '/api/rbac/roles/',
    method: 'get'
  })
}

export function getSelectedRoles(data = {}) {
  return request({
    url: '/auth/role/list',
    method: 'post',
    data
  })
}

export function matchRoles(id, data) {
  return request({
    url: `/api/rbac/match_roles/${id}/`,
    method: 'patch',
    data
  })
}

export function commonMatchRoles(id, data) {
  return request({
    url: `/api/rbac/common_users/${id}/match_roles`,
    method: 'patch',
    data
  })
}

export function add(data) {
  return request({
    url: '/externaladmin/authService/role/complexCreateOrUpdate',
    method: 'post',
    data
  })
}

export function del(idList, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/role/delRole',
    method: 'post',
    data: {
      idList
    }
  })
}

export function edit(id, data) {
  return request({
    url: '/api/rbac/roles/' + id + '/',
    method: 'put',
    data
  })
}

export function update(id, data) {
  return request({
    url: '/api/rbac/roles/' + id + '/',
    method: 'patch',
    data
  })
}

export function retrieve(id) {
  return request({
    url: '/api/rbac/roles/' + id + '/',
    method: 'get'
  })
}

export function save(id, data) {
  return request({
    url: '/api/rbac/roles/' + id + '/',
    method: 'patch',
    data
  })
}
