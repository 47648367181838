import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function add(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/createAdminUser',
    method: 'post',
    data
  })
}

export function employeeList(params) {
  return request({
    url: '/api/rbac/employee_list/',
    method: 'get',
    params
  })
}

export function subDistributor(data) {
  return request({
    url: `/api/rbac/admin_users/${data}/sub_distributor/`,
    method: 'get',
    data
  })
}
export function read(id, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/getUserById',
    method: 'post',
    data: { id }
  })
}

export function del(id, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/rbac/admin_users/' + id + '/',
    method: 'delete'
  })
}

export function edit(id, data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/rbac/admin_users/' + id + '/',
    method: 'put',
    data
  })
}

export function update(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/auth/user/updateUser',
    method: 'post',
    data
  })
}

export function resetPassword(id, data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: `/api/rbac/admin_users/${id}/reset_password/`,
    method: 'patch',
    data
  })
}

export function updateAdminType(id, data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/rbac/admin_users/admin_type/',
    method: 'patch',
    data
  })
}

export function updatePasswd(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/rbac/admin_users/change_password/',
    method: 'patch',
    data
  })
}

export function reportUser(data) {
  return request({
    url: '/api/order/report_user/',
    method: 'get',
    data
  })
}
