<template>
  <div class="app-container baseUserRoleComponent app-flex-col-container" v-loading="loading">
    <div class="flex-col-content">
      <div class="title" @click="back">
        <i class="el-icon-d-arrow-left"></i>
        <span>{{ userTitle }}</span>
      </div>
      <BaseForm style="width: 50%" size="small" ref="form" label-width="100PX" :cols="formField" :form="form">
        <template #nameSlot="{ scoped: { prop, placeholder } }">
          <el-input size="small" type="text" style="width: 250px" :placeholder="placeholder" v-model="form[prop]" />
        </template>

        <template #passwordSlot="{ scoped: { prop, placeholder } }">
          <el-input v-model="form[prop]" :placeholder="placeholder" type="password" show-password auto-complete="on" />
        </template>

        <template #password2Slot="{ scoped: { prop, placeholder } }">
          <el-input v-model="form[prop]" :placeholder="placeholder" show-password type="password" auto-complete="on" />
        </template>
        <template #rolesSlot="{ scoped: { prop, placeholder } }">
          <RoleSelectList
            style="width: 250px"
            multiple
            :menus="adminRoleList"
            :keyObj="adminRoleListProps"
            :keyword.sync="form[prop]"
            :placeholder="placeholder"
          />
        </template>
      </BaseForm>
    </div>
    <div class="btn-wrapper bottom">
      <el-button @click="back"> 取消 </el-button>
      <el-button type="primary" @click="doSubmit" :loading="loading"> 提交 </el-button>
    </div>
  </div>
</template>

<script>
import RoleSelectList from '../roleSelectList'
import { addUserField, editUserField } from './formField'
import { commonFromMixin } from '@/mixins'
import { edit } from '@/api/user'
import { add, read } from '@/api/adminUser'
import { getSelectedRoles } from '@/api/role'
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import { mapGetters } from 'vuex'

export default {
  mixins: [commonFromMixin],
  components: {
    RoleSelectList
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['adminRoleList', 'adminRoleListProps']),
    userTitle() {
      return this.isEdit ? '编辑用户' : '添加用户'
    }
  },
  data() {
    if (!this.isEdit) {
      const confirmPass = (rule, value, callback) => {
        if (this.form.password !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          this.$refs.form.clearValidate('password')
          callback()
        }
      }

      const confirmPass1 = (rule, value, callback) => {
        if (!this.form.confirmPassword) return callback()
        if (this.form.confirmPassword !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          this.$refs.form.clearValidate('confirmPassword')
          callback()
        }
      }
      addUserField[3].rule[2] = {
        validator: confirmPass1
      }
      addUserField[4].rule[1] = {
        validator: confirmPass
      }
    }
    return {
      formField: this.isEdit ? editUserField : addUserField,
      id: this.$route.query.id,
      form: {},
      oForm: {},
      loading: false
    }
  },
  created() {
    this.initEdit()
  },
  methods: {
    back() {
      this.$router.back()
    },
    async initEdit() {
      if (!this.isEdit) return
      if (!this.id) {
        this.back()
      }
      const { code, detail } = await read(this.id)
      if ($SUC({ code })) {
        const { accountName, roleDTOList, nickName, email, password, confirmPassword } = detail || {}
        this.form = {
          accountName,
          nickName,
          email,
          password,
          confirmPassword,
          roleIdList: (roleDTOList || []).map(({ id }) => id)
        }
        this.oForm = deepClone(this.form)
        const { form } = this.$refs
        form && form.resetFields()
      }
    },
    async doSubmit() {
      console.log(this.form)
      const valid = await this.validate()
      console.log(valid, this.isEdit)
      if (!valid) return
      if (this.isEdit) {
        this.doEdit()
      } else {
        this.doAdd()
      }
    },
    async doAdd() {
      console.log(this.form)
      this.loading = true
      const res = await awaitResolve(add(this.form))
      if (res) {
        this.success()
        this.back()
      }
      this.loading = false
    },

    async doEdit() {
      this.loading = true
      const { roleIdList, password, ...params } = this.form
      let oRoles = this.oForm.roleIdList
      let roles = this.form.roleIdList
      const delRoleIdList = oRoles.filter((id) => !roles.includes(id))
      const createRoleIdList = roles.filter((id) => !oRoles.includes(id))
      const res = await awaitResolveLoading(
        edit({
          id: this.id,
          ...params,
          delRoleIdList,
          createRoleIdList
        })
      )
      if (res) {
        this.success()
        this.back()
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.baseUserRoleComponent {
  padding: 30px;
  .title {
    padding: 15px;
    background: #fff;
    cursor: pointer;
    i {
      margin-right: 10px;
    }
  }
  .btn-wrapper {
    margin-top: 30px;
    text-align: center;
  }
}
</style>
